import 'intl-tel-input/build/css/intlTelInput.css'
import './main.scss'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'

/* Landing pages have a similar script at app/assets/javascripts/global/dynamic-require-intl-tel-input.coffee.erb */

const setLabelPadding = (node) => {
  const fieldControl = node.closest('.t-field__control-wrapper')
  if (fieldControl) {
    const countrySelectCont = fieldControl.querySelector('.iti__flag-container')
    const label = fieldControl.querySelector('label')
    if (label) {
      label.style.paddingLeft = `${countrySelectCont.offsetWidth + 10}px`
    }
  }
}

export const attachInternationalPhone = (
  node,
  { initialCountry, customContainerClasses, countryCodeField, autoPlaceholder }
) => {
  if (node.classList.contains('intl-tel-initialized')) {
    return
  }

  node.classList.add('intl-tel-initialized')

  const iti = intlTelInput(node, {
    dropdownContainer: document.body,
    autoPlaceholder: autoPlaceholder,
    separateDialCode: !!countryCodeField,
    preferredCountries: initialCountry ? [initialCountry] : [],
    initialCountry: initialCountry,
    customContainer: customContainerClasses,
  })

  if (countryCodeField) {
    countryCodeField.style.display = 'none'

    if (countryCodeField.value) {
      iti.setCountry(countryCodeField.value)
    }

    // Reset label padding if the element was initially hidden and then shows up. The calculation
    // depends on the element being visible so if it were hidden initially, it will be wrong.
    // Instead, set the padding once the node becomes visible, for example if it's inside a modal.
    var observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target == node && entry.isIntersecting) {
          setLabelPadding(node)
          observer.disconnect()
        }
      })
    })

    observer.observe(node)

    // bind country dropdown to iti's country dropdown
    return node.addEventListener('countrychange', function (e) {
      setLabelPadding(node)
      if (iti.getSelectedCountryData().iso2) {
        return (countryCodeField.value = iti
          .getSelectedCountryData()
          .iso2.toUpperCase())
      }
    })
  }
}
